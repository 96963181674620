<template>
    <div>
	    <v-card flat>
            <v-card-title>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="4">
                        <h3>Detalles del proveedor</h3>
                    </v-col>
                </v-row>
            </v-card-title>
		    <v-card-text>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="6" lg="4">
                        <details-card :detailsItems="detailsItems"></details-card>        
                    </v-col>
                </v-row>
		    </v-card-text>
            <v-card-actions>
                 <v-row justify="center">
                    <v-col cols="12" md="6" lg="4" class="text-right">
                        <v-btn color="secondary" tile :to="{ name: 'Proveedores' }">Regresar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
	    </v-card>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Nombre', index: 'name', valor: null },
                        { concepto: 'R.F.C.', index: 'rfc', valor: null },
                        { concepto: 'Razón Social', index: 'business_name', valor: null },
                        { concepto: 'Estado', index: 'address_code', valor: null },
                        { concepto: 'Municipio', index: 'address_code', valor: null },
                        { concepto: 'Colonia', index: 'neighborhood', valor: null },
                        { concepto: 'Calle', index: 'street', valor: null },
                        { concepto: 'No. Exterior', index: 'ext_number', valor: null },
                        { concepto: 'No. Interior', index: 'int_number', valor: null },
                        { concepto: 'Contacto', index: 'contact', valor: null },
                        { concepto: 'Teléfono', index: 'phone', valor: null },
                        { concepto: 'Email', index: 'email', valor: null },
                        
                    ],
                }
            ]
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.$http.get(this.$store.state.apiRoute+'/suppliers/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data[y.index]
                    })
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
</style>