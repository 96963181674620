<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" lg="4">
						<v-text-field v-model="tableProps.search" label="Buscar una unidad" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="12" lg="2" class='flexbox-right' v-permission="'catalog_unit.create'">
						<v-btn class='btn-yellow' elevation="0" block :to="{ name: 'CatalogosUnidadesRegistro' }" tile>Nueva unidad</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

        <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn icon color="white" text v-bind="attrs" @click="snackbar.visible = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
export default {
	data(){
		return {
			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'catalog_unit.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'removeRecord',
                    permissions: 'catalog_unit.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			}
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/catalogs/unit')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'CatalogosUnidadesEdicion', params: { id: x.slug } }

					x['parameters'] = { id: x.slug }

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
                // eslint-disable-next-line no-console
                console.log(parameters)
				this.modalProps = {
					visible: true,
					width: '500',
					title: 'Eliminar unidad',
					text: '¿Desea continuar?',
					buttons: [
						{
							text: 'Cancelar',
							color: 'primary',
							textColor: 'white',
							btnTypeText: true,
							method: 'cancel',
							parameters: {}
						},
						{
							text: 'Aceptar',
							color: 'secondary',
							textColor: 'white--text mx-1',
							btnTypeText: false,
							method: 'confirm',
							parameters: {
								id: parameters.id
							}
						}
					]
				}
                this.modalProps.visible = true
            },
            confirm(parameters){
                this.$http.delete(this.$store.state.apiRoute+'/catalogs/unit/'+parameters.id+'/delete')
                .then(() => {
					this.snackbar = {
						visible: true,
						color: 'green',
						text: 'Se eliminó la unidad',
						timeout: 3000
					}
                    this.index()
                }).catch(error => {
                    console.error(error);
					this.snackbar = {
						visible: true,
						color: 'red',
						text: 'Error al eliminar. Intenta de nuevo',
						timeout: 3000
					}
                })
                .finally(() => {
                    this.modalProps.visible = false
                })
            },
            cancel(){
                this.modalProps.visible = false
            }
	},	
}
</script>

<style>

</style>