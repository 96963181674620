<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Editar orden de trabajo
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="8">
                    <v-form ref="form" @submit.prevent="save">
                        <orden-de-venta-form :values="form" :rules="rules"></orden-de-venta-form>
                    </v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="8" class="text-right">
					<v-btn tile text color="primary" :to="{ name: 'OrdenesDeVenta' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import OrdenDeVentaForm from "./Form.vue";

export default {
    components: {
        'orden-de-venta-form': OrdenDeVentaForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                client_id: '',
                building_id: '',
                discount: 0,
                quote_id: 0,
                date: '',
                products: []
            },
            rules: {
                client_id: [
                    v => !!v || 'Cliente es requerido'
                ],
                building_id: [
                    v => !!v || 'Obra es requerido'
                ],
                discount: [
                    v => v >= 0 && v <= 100 || 'Descuento debe ser entre 0 y 100'
                ],
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
            this.$http.get(this.$store.state.apiRoute+'/saleOrders/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    client_id: response.data.data.client_id,
                    building_id: response.data.data.building_id,
                    discount: response.data.data.discount,
                    quote_id: response.data.data.quote_id,
                    date: response.data.data.date.split('/').reverse().join('-'),
                    products: response.data.variants.map((x) => {
                        return {
                            big_product_id: x.big_product_id,
                            product: x.product,
                            quantity: x.quantity,
                            amount: x.amount,
                            subtotal: parseFloat(x.quantity * x.amount),
                            actions: ''
                        }
                    })
                }                
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                var form = this.form
                form.products = this.form.products.map((x) => {
                    return {
                        big_product_id: x.big_product_id,                        
                        quantity: x.quantity,
                        amount: x.amount
                    }
                })
                
                this.$http.put(this.$store.state.apiRoute+'/saleOrders/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha actualizado correctamente la orden de trabajo <b></b>'
                    this.$router.push({ name: 'OrdenesDeVenta' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>