<template>
    <v-snackbar v-model="props.visible" :color="props.color" :timeout="timeout" bottom transition="scale-transition">
        <v-row justify="space-between" align="center" class="pl-3">
            <div v-html="props.text"></div>
            <v-btn color="white" text icon @click="props.visible = false">
                <v-icon>mdi-close</v-icon>    
            </v-btn>    
        </v-row>
    </v-snackbar>
</template>
<script>
    export default {
        name: 'snackbar',
        props: ['props'],
        computed: {
            timeout(){
                return this.props.timeout == 0 ? "-1" : this.props.timeout
            }
        }
    }
</script>