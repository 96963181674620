<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Editar materia prima
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="5">
                    <v-form ref="form" @submit.prevent="save">
                        <materia-prima-form ref="materiaPrimaForm" :values="form" :rules="rules" @save="save"></materia-prima-form>
                    </v-form>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import MateriaPrimaForm from "./Form.vue";
export default {
    components: {
        'materia-prima-form': MateriaPrimaForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            response: {
                "data": {
                    "product": {
                        "name": "Coquita",
                        "description": "Una coquita bien rica y más fría",
                        "brand": "Refresco",
                        "unit_id": "mili-litros"
                    },
                    "suppliers": [
                        {
                            "name": "Aceros Del Bajío",
                            "slug": "aceros-del-bajio",
                        },
                        {
                            "name": "Proveedor Dos",
                            "slug": "proveedor-dos",
                        }
                    ],
                    "variants": [
                        {
                            "id": "1",
                            "size": "chica",
                            "color": "negro",
                            "price": "9.5"
                        },
                        {
                            "id": "2",
                            "size": "mediana",
                            "color": "azul",
                            "price": "13"
                        },
                    ],
                }
            },
            form: {
                name: '',
                type: '',
                description: '',
                brand: '',
                unit_id: '',
                suppliers: [],
                variants: [],
            },
            rules: {
               name: [
                    v => !!v || 'Nombre es requerido'
                ],
                type: [
                    v => v.length > 0 || 'Tipo es requerido'
                ],
                description: [
                    v => !!v || 'Descripción es requerido'
                ],
                brand: [
                    // v => !!v || 'Marca es requerido'
                ],
                unit_id: [
                    v => !!v || 'Unidad es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate() && this.form.type.length > 0			
        },
        index(){
            this.$http.get(this.$store.state.apiRoute+'/products/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    name: response.data.product.name,
                    type: response.data.product.type.split(','),
                    description: response.data.product.description,
                    brand: response.data.product.brand,
                    unit_id: response.data.product.unit_id,
                    
                    suppliers: response.data.suppliers,
                    variants: response.data.variants.map(x => {
                        return { id: x.id, size: x.size, color: x.color, price: x.price, isNew: false, edited: false }
                    }),
                }
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){               
                let form = JSON.parse(JSON.stringify(this.form))
                form.type = this.form.type.join(',')
                form['supplier_id'] = this.form.suppliers.map(x => {
                    return x.slug
                })
                form.variants = this.form.variants.map(x => {
                    return { id: x.id, size: x.size, color: x.color, price: x.price, edited: x.edited, isNew: x.isNew }
                })

                this.$http.put(this.$store.state.apiRoute+'/products/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha actualizado correctamente la materia prima <b>'+this.form.name+'</b>'
                    this.$router.push({name: 'MateriasPrimas'})
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.$refs.materiaPrimaForm.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
        }
    },
}
</script>

<style>

</style>