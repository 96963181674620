// import Vue from 'vue'
// import VueRouter, { RouteConfig } from 'vue-router'

import Login from '../views/Layouts/Login.vue'
import Dashboard from '../views/Modules/General/Dashboard.vue'

import Obras from '../views/Modules/Obras/Index.vue'
// import ObrasRegistro from '../views/Modules/Obras/Create.vue'
import ObrasEdicion from '../views/Modules/Obras/Edit.vue'
import ObrasDetalles from '../views/Modules/Obras/Details.vue'

import Usuarios from '../views/Modules/Usuarios/Index.vue'
import UsuariosRegistro from '../views/Modules/Usuarios/Create.vue'
import UsuariosEdicion from '../views/Modules/Usuarios/Edit.vue'
import UsuariosDetalles from '../views/Modules/Usuarios/Details.vue'

import Proveedores from '../views/Modules/Compras/Proveedores/Index.vue'
import ProveedoresRegistro from '../views/Modules/Compras/Proveedores/Create.vue'
import ProveedoresEdicion from '../views/Modules/Compras/Proveedores/Edit.vue'
import ProveedoresDetalles from '../views/Modules/Compras/Proveedores/Details.vue'

import MateriasPrimas from '../views/Modules/Compras/MateriasPrimas/Index.vue'
import MateriasPrimasRegistro from '../views/Modules/Compras/MateriasPrimas/Create.vue'
import MateriasPrimasEdicion from '../views/Modules/Compras/MateriasPrimas/Edit.vue'
import MateriasPrimasDetalles from '../views/Modules/Compras/MateriasPrimas/Details.vue'

import Productos from '../views/Modules/Ventas/Productos/Index.vue'
import ProductosRegistro from '../views/Modules/Ventas/Productos/Create.vue'
import ProductosEdicion from '../views/Modules/Ventas/Productos/Edit.vue'
import ProductosDetalles from '../views/Modules/Ventas/Productos/Details.vue'

import Requisiciones from '../views/Modules/Compras/Requisiciones/Index.vue'
import RequisicionesRegistro from '../views/Modules/Compras/Requisiciones/Create.vue'
import RequisicionesEdicion from '../views/Modules/Compras/Requisiciones/Edit.vue'
import RequisicionesDetalles from '../views/Modules/Compras/Requisiciones/Details.vue'

import Clientes from '../views/Modules/Ventas/Clientes/Index.vue'
import ClientesRegistro from '../views/Modules/Ventas/Clientes/Create.vue'
import ClientesEdicion from '../views/Modules/Ventas/Clientes/Edit.vue'
import ClientesDetalles from '../views/Modules/Ventas/Clientes/Details.vue'

// import Vendedores from '../views/Modules/Ventas/Vendedores/Index.vue'
// import VendedoresRegistro from '../views/Modules/Ventas/Vendedores/Create.vue'
// import VendedoresEdicion from '../views/Modules/Ventas/Vendedores/Edit.vue'
// import VendedoresDetalles from '../views/Modules/Ventas/Vendedores/Details.vue'

import Cotizaciones from '../views/Modules/Ventas/Cotizaciones/Index.vue'
import CotizacionesRegistro from '../views/Modules/Ventas/Cotizaciones/Create.vue'
import CotizacionesEdicion from '../views/Modules/Ventas/Cotizaciones/Edit.vue'
import CotizacionesDetalles from '../views/Modules/Ventas/Cotizaciones/Details.vue'

import OrdenesDeVenta from '../views/Modules/Ventas/OrdenesDeVenta/Index.vue'
import OrdenesDeVentaRegistro from '../views/Modules/Ventas/OrdenesDeVenta/Create.vue'
import OrdenesDeVentaEdicion from '../views/Modules/Ventas/OrdenesDeVenta/Edit.vue'
import OrdenesDeVentaDetalles from '../views/Modules/Ventas/OrdenesDeVenta/Details.vue'
import OrdenesDeVentaSeguimientos from '../views/Modules/Ventas/OrdenesDeVenta/Seguimientos/Create.vue'
import OrdenesDeVentaPagos from '../views/Modules/Ventas/OrdenesDeVenta/Pagos/Create.vue'

import OrdenesDeCompra from '../views/Modules/Compras/OrdenesDeCompra/Index.vue'
import OrdenesDeCompraRegistro from '../views/Modules/Compras/OrdenesDeCompra/Create.vue'
import OrdenesDeCompraEdicion from '../views/Modules/Compras/OrdenesDeCompra/Edit.vue'
import OrdenesDeCompraDetalles from '../views/Modules/Compras/OrdenesDeCompra/Details.vue'
import OrdenesDeCompraPagos from '../views/Modules/Compras/OrdenesDeCompra/Pagos/Create.vue'

import Bancos from '../views/Modules/Contabilidad/Bancos/Index.vue'
import BancosRegistro from '../views/Modules/Contabilidad/Bancos/Create.vue'
import BancosEdicion from '../views/Modules/Contabilidad/Bancos/Edit.vue'
import BancosDetalles from '../views/Modules/Contabilidad/Bancos/Details.vue'

import CatalogosBancos from '../views/Modules/Catalogos/Bancos/Index.vue'
import CatalogosBancosRegistro from '../views/Modules/Catalogos/Bancos/Create.vue'
import CatalogosBancosEdicion from '../views/Modules/Catalogos/Bancos/Edit.vue'
import CatalogosUnidades from '../views/Modules/Catalogos/Unidades/Index.vue'
import CatalogosUnidadesRegistro from '../views/Modules/Catalogos/Unidades/Create.vue'
import CatalogosUnidadesEdicion from '../views/Modules/Catalogos/Unidades/Edit.vue'

import VueGateMiddleware from '../middleware/VueGateMiddleware';
import AuthMiddleware from '../middleware/AuthMiddleware';

// Vue.use(VueRouter)

  const general = [
    {
      path: '*',
      redirect: '/obras'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        auth: false,
        title: 'Sistema M - Inicio de sesión',
        middlewares: {AuthMiddleware},
        permissions: 'all'
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        auth: true,
        title: 'Sistema M - Dashboard',
        middlewares: {AuthMiddleware},
        permissions: 'all'
      }
    },
  ]
  
  const modObras = [
    {
      path: '/obras',
      name: 'Obras',
      component: Obras,
      meta: {
        auth: true,
        title: 'Sistema M - Obras',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'buildings.show'
      },
    },
    // {
    //   path: '/obras/registro',
    //   name: 'ObrasRegistro',
    //   component: ObrasRegistro,
    //   meta: {
    //     auth: true
    //   },
    // },
    {
      path: '/obras/:id/edicion',
      name: 'ObrasEdicion',
      component: ObrasEdicion,
      meta: {
        auth: true,
        title: 'Sistema M - Obras - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'buildings.update'
      },
    },
    {
      path: '/obras/:id/detalles',
      name: 'ObrasDetalles',
      component: ObrasDetalles,
      meta: {
        auth: true,
        title: 'Sistema M - Obras - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'buildings.show'
      },
    },
  ]

  const modCompras = [
    // Proveedores
    {
      path: '/proveedores',
      name: 'Proveedores',
      component: Proveedores,
      meta: {
        auth: true,
        title: 'Sistema M | Proveedores',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'suppliers.show'
      }
    },
    {
      path: '/proveedores/registro',
      name: 'ProveedoresRegistro',
      component: ProveedoresRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Proveedores - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'suppliers.create'
      }
    },
    {
      path: '/proveedores/:id/edicion',
      name: 'ProveedoresEdicion',
      component: ProveedoresEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Proveedores - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'suppliers.update'
      }
    },
    {
      path: '/proveedores/:id/detalles',
      name: 'ProveedoresDetalles',
      component: ProveedoresDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Proveedores - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'suppliers.show'
      }
    },
    // Materias Primas
    {
      path: '/materias-primas',
      name: 'MateriasPrimas',
      component: MateriasPrimas,
      meta: {
        auth: true,
        title: 'Sistema M | Materías primas',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'products.show'
      }
    },
    {
      path: '/materias-primas/registro',
      name: 'MateriasPrimasRegistro',
      component: MateriasPrimasRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Materías primas - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'products.create'
      }
    },
    {
      path: '/materias-primas/:id/edicion',
      name: 'MateriasPrimasEdicion',
      component: MateriasPrimasEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Materías primas - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'products.update'
      }
    },
    {
      path: '/materias-primas/:id/detalles',
      name: 'MateriasPrimasDetalles',
      component: MateriasPrimasDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Materías primas - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'products.show'
      }
    },
    // Productos
    {
      path: '/productos',
      name: 'Productos',
      component: Productos,
      meta: {
        auth: true,
        title: 'Sistema M | Productos',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'bigProducts.show'
      }
    },
    {
      path: '/productos/registro',
      name: 'ProductosRegistro',
      component: ProductosRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Productos - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'bigProducts.create'
      }
    },
    {
      path: '/productos/:id/edicion',
      name: 'ProductosEdicion',
      component: ProductosEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Productos - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'bigProducts.update'
      }
    },
    {
      path: '/productos/:id/detalles',
      name: 'ProductosDetalles',
      component: ProductosDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Productos - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'bigProducts.show'
      }
    },
    // Requisiciones
    {
      path: '/requisiciones',
      name: 'Requisiciones',
      component: Requisiciones,
      meta: {
        auth: true,
        title: 'Sistema M | Requisiciones',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'requisitions.show'
      }
    },
    {
      path: '/requisiciones/registro/:id?',
      name: 'RequisicionesRegistro',
      component: RequisicionesRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Requisiciones - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'requisitions.create'
      }
    },
    {
      path: '/requisiciones/:id/edicion',
      name: 'RequisicionesEdicion',
      component: RequisicionesEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Requisiciones - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'requisitions.update'
      }
    },
    {
      path: '/requisiciones/:id/detalles',
      name: 'RequisicionesDetalles',
      component: RequisicionesDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Requisiciones - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'requisitions.show'
      }
    },
    // Ordenes de compra
    {
      path: '/ordenes-de-compra',
      name: 'OrdenesDeCompra',
      component: OrdenesDeCompra,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de compra',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.show'
      }
    },
    {
      path: '/ordenes-de-compra/registro/:id?',
      name: 'OrdenesDeCompraRegistro',
      component: OrdenesDeCompraRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de compra - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.create'
      }
    },
    {
      path: '/ordenes-de-compra/:id/edicion',
      name: 'OrdenesDeCompraEdicion',
      component: OrdenesDeCompraEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de compra - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.update'
      }
    },
    {
      path: '/ordenes-de-compra/:id/detalles',
      name: 'OrdenesDeCompraDetalles',
      component: OrdenesDeCompraDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de compra - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.show'
      }
    },
    {
      path: '/ordenes-de-compra/:id/pago',
      name: 'OrdenesDeCompraPagos',
      component: OrdenesDeCompraPagos,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de compra - Pago',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.create'
      }
    },
  ]
  
  const modVentas = [
    // Clientes
    {
      path: '/clientes',
      name: 'Clientes',
      component: Clientes,
      meta: {
        auth: true,
        title: 'Sistema M | Clientes',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'clients.show'
      }
    },
    {
      path: '/clientes/registro',
      name: 'ClientesRegistro',
      component: ClientesRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Clientes - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'clients.create'
      }
    },
    {
      path: '/clientes/:id/edicion',
      name: 'ClientesEdicion',
      component: ClientesEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Clientes - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'clients.update'
      }
    },
    {
      path: '/clientes/:id/detalles',
      name: 'ClientesDetalles',
      component: ClientesDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Clientes - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'clients.show'
      }
    },

    // Vendedores
    // {
    //   path: '/vendedores',
    //   name: 'Vendedores',
    //   component: Vendedores,
    //   meta: {
    //     auth: true,
    //     title: 'Sistema M | Vendedores',
    //     middlewares: {AuthMiddleware, VueGateMiddleware},
    //     permissions: 'sellers.show'
    //   }
    // },
    // {
    //   path: '/vendedores/registro',
    //   name: 'VendedoresRegistro',
    //   component: VendedoresRegistro,
    //   meta: {
    //     auth: true,
    //     title: 'Sistema M | Vendedores - Registro',
    //     middlewares: {AuthMiddleware, VueGateMiddleware},
    //     permissions: 'sellers.create'
    //   }
    // },
    // {
    //   path: '/vendedores/:id/edicion',
    //   name: 'VendedoresEdicion',
    //   component: VendedoresEdicion,
    //   meta: {
    //     auth: true,
    //     title: 'Sistema M | Vendedores - Edición',
    //     middlewares: {AuthMiddleware, VueGateMiddleware},
    //     permissions: 'sellers.update'
    //   }
    // },
    // {
    //   path: '/vendedores/:id/detalles',
    //   name: 'VendedoresDetalles',
    //   component: VendedoresDetalles,
    //   meta: {
    //     auth: true,
    //     title: 'Sistema M | Vendedores - Detalles',
    //     middlewares: {AuthMiddleware, VueGateMiddleware},
    //     permissions: 'sellers.show'
    //   }
    // },

    // Cotizaciones
    {
      path: '/cotizaciones',
      name: 'Cotizaciones',
      component: Cotizaciones,
      meta: {
        auth: true,
        title: 'Sistema M | Cotizaciones',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'quotes.show'
      }
    },
    {
      path: '/cotizaciones/registro/:id?',
      name: 'CotizacionesRegistro',
      component: CotizacionesRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Cotizaciones - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'quotes.create'
      }
    },
    {
      path: '/cotizaciones/:id/edicion',
      name: 'CotizacionesEdicion',
      component: CotizacionesEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Cotizaciones - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'quotes.update'
      }
    },
    {
      path: '/cotizaciones/:id/detalles',
      name: 'CotizacionesDetalles',
      component: CotizacionesDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Cotizaciones - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'quotes.show'
      }
    },

    // Ordenes de venta -- trabajo
    {
      path: '/ordenes-de-trabajo',
      name: 'OrdenesDeVenta',
      component: OrdenesDeVenta,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.show'
      }
    },
    {
      path: '/ordenes-de-trabajo/registro/:id',
      name: 'OrdenesDeVentaRegistro',
      component: OrdenesDeVentaRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.create'
      }
    },
    {
      path: '/ordenes-de-trabajo/:id/edicion',
      name: 'OrdenesDeVentaEdicion',
      component: OrdenesDeVentaEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.update'
      }
    },
    {
      path: '/ordenes-de-trabajo/:id/detalles',
      name: 'OrdenesDeVentaDetalles',
      component: OrdenesDeVentaDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.show'
      }
    },
    {
      path: '/ordenes-de-trabajo/:id/seguimiento',
      name: 'OrdenesDeVentaSeguimientos',
      component: OrdenesDeVentaSeguimientos,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo - Seguimiento',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.create'
      }
    },
    {
      path: '/ordenes-de-trabajo/:id/pago',
      name: 'OrdenesDeVentaPagos',
      component: OrdenesDeVentaPagos,
      meta: {
        auth: true,
        title: 'Sistema M | Órdenes de trabajo - Pago',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'saleOrders.create'
      }
    },
  ]

  const modContabilidad = [
    // Bancos
    {
      path: '/bancos',
      name: 'Bancos',
      component: Bancos,
      meta: {
        auth: true,
        title: 'Sistema M | Bancos',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'banks.show'
      }
    },
    {
      path: '/bancos/registro',
      name: 'BancosRegistro',
      component: BancosRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Bancos - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'banks.create'
      }
    },
    {
      path: '/bancos/:id/edicion',
      name: 'BancosEdicion',
      component: BancosEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Bancos - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'banks.update'
      }
    },
    {
      path: '/bancos/:id/detalles',
      name: 'BancosDetalles',
      component: BancosDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Bancos - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'banks.show'
      }
    },
  ]

  const modCatalogos = [
    // Bancos
    {
      path: '/catalogos/bancos',
      name: 'CatalogosBancos',
      component: CatalogosBancos,
      meta: {
        auth: true,
        title: 'Sistema M | Catálogos - Bancos',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_bank.show'
      }
    },
    {
      path: '/catalogos/bancos/registro',
      name: 'CatalogosBancosRegistro',
      component: CatalogosBancosRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Catálogos - Bancos - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_bank.create'
      }
    },
    {
      path: '/catalogos/bancos/:id/edicion',
      name: 'CatalogosBancosEdicion',
      component: CatalogosBancosEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Catálogos - Bancos - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_bank.update'
      }
    },
    
    // Unidades
    {
      path: '/catalogos/unidades',
      name: 'CatalogosUnidades',
      component: CatalogosUnidades,
      meta: {
        auth: true,
        title: 'Sistema M | Unidades - Bancos',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_unit.show'
      }
    },
    {
      path: '/catalogos/unidades/registro',
      name: 'CatalogosUnidadesRegistro',
      component: CatalogosUnidadesRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Catálogos - Unidades - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_unit.create'
      }
    },
    {
      path: '/catalogos/unidades/:id/edicion',
      name: 'CatalogosUnidadesEdicion',
      component: CatalogosUnidadesEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Catálogos - Unidades - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'catalog_unit.update'
      }
    },
  ]

  const modUsuarios = [
    {
      path: '/usuarios',
      name: 'Usuarios',
      component: Usuarios,
      meta: {
        auth: true,
        title: 'Sistema M | Usuarios',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'users.show'
      },
    },
    {
      path: '/usuarios/registro',
      name: 'UsuariosRegistro',
      component: UsuariosRegistro,
      meta: {
        auth: true,
        title: 'Sistema M | Usuarios - Registro',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'users.create'
      },
    },
    {
      path: '/usuarios/:id/edicion',
      name: 'UsuariosEdicion',
      component: UsuariosEdicion,
      meta: {
        auth: true,
        title: 'Sistema M | Usuarios - Edición',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'users.update'
      },
    },
    {
      path: '/usuarios/:id/detalles',
      name: 'UsuariosDetalles',
      component: UsuariosDetalles,
      meta: {
        auth: true,
        title: 'Sistema M | Usuarios - Detalles',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'users.show'
      },
    },
  ]

const modRoles = [
    {
        path: '/roles',
        name: 'Roles',
        component: () => import('../views/Modules/Roles/Index.vue'),
        meta: {
            auth: true,
            title: 'Sistema M | Roles',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.show'
        },
    },
    {
        path: '/roles/registro',
        name: 'RolesRegistro',
        component: () => import('../views/Modules/Roles/Create.vue'),
        meta: {
            auth: true,
            title: 'Sistema M | Roles - Registro',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.create'
        },
    },
    {
        path: '/roles/:id/edicion',
        name: 'RolesEdicion',
        component: () => import('../views/Modules/Roles/Edit.vue'),
        meta: {
            auth: true,
            title: 'Sistema M | Roles - Edición',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.update'
        },
    },
]


  
export const routes = [
    ... general,
    ... modObras,
    ... modCompras,
    ... modVentas,
    ... modContabilidad,
    ... modCatalogos,
    ... modUsuarios,
    ...modRoles,
  ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
