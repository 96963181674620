<template>
    <div>
	    <v-card flat>
            <v-card-title>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="4">
                        <h3>Detalles del cliente</h3>
                    </v-col>
                </v-row>
            </v-card-title>
		    <v-card-text>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="6" lg="4">
                        <details-card :detailsItems="detailsItems"></details-card>        
                    </v-col>
                </v-row>
		    </v-card-text>
            <v-card-actions>
                 <v-row justify="center">
                    <v-col cols="12" md="6" lg="4" class="text-right">
                        <v-btn color="secondary" tile :to="{ name: 'Clientes' }">Regresar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
	    </v-card>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Nombre', index: 'name', valor: null },
                        { concepto: 'R.F.C.', index: 'rfc', valor: null },
                        { concepto: 'Razón Social', index: 'business_name', valor: null },
                        { concepto: 'Estado', index: 'state', valor: null },
                        { concepto: 'Municipio', index: 'city', valor: null },
                        { concepto: 'Colonia', index: 'neighborhood', valor: null },
                        { concepto: 'Calle', index: 'street', valor: null },
                        { concepto: 'No. Exterior', index: 'ext_number', valor: null },
                        { concepto: 'No. Interior', index: 'int_number', valor: null },
                        { concepto: 'Contacto', index: 'contact', valor: null },
                        { concepto: 'Teléfono', index: 'phone', valor: null },
                        { concepto: 'Email', index: 'email', valor: null },
                        
                    ],
                }
            ]
        }
    },
    mounted(){
		this.getCountries()
        this.index()
    },
    methods: {
        getCountries(){
			this.$http.get(this.$store.state.countryApiRoute+'/api/getCountries')
			.then((response) => {
				this.countries = response.data
			})
			.catch((error) => {
				error
			})
		},
        index(){
            this.$http.get(this.$store.state.apiRoute+'/clients/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data[y.index] === null ? '' : response.data[y.index]

                        if(y.index === 'city' && response.data.address_code !== null){
                            const type = response.data.address_code.substring(0, 2)
                            const code = response.data.address_code.substring(3)

                            this.$http.get(this.$store.state.countryApiRoute+'/api/getCityDetails/'+code)
                            .then((result) => { 
                                y['valor'] = result.data.city.name
                                console.log(x.items.find(z => z.index === 'state'))
                                x.items.find(z => z.index === 'state').valor = result.data.state.name
                            })
                        }
                    })
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
</style>