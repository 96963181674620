<template>
    <div>
        <v-app-bar app class='navbar-color' elevation="1" dense>
            <div class="d-flex align-center">
                <!-- <v-btn v-if="$store.state.menu" dark icon text @click="$store.state.minimenu = !$store.state.minimenu">
                    <v-icon>mdi-menu</v-icon>
                </v-btn> -->
                <v-btn v-if="!$store.state.menu" dark icon text @click="$store.state.menu = !$store.state.menu">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text rounded v-on="on" dark>
                        <v-avatar color="primary" size="36">
                            <span>{{ $store.state.loggedUsername.split(' ').map((x) => { return x[0] }).join('').substring(0, 2) }}</span>
                        </v-avatar>
                        <span class="font-weight-200 mx-2 d-none d-sm-flex">{{ $store.state.loggedUsername }}</span>
                        <v-icon class='yellow-font'>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in user_menu" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" link>
                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon text v-on="on" dark>
                        <v-icon class='yellow-font'>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        data(){
            return {
                dialog: false,
                items: [],
                user_menu: [],
                menu: [
                    {
                        title: 'Feature',
                        link: '/'
                    },
                ]
            }
        },
        methods: {
            logout(){
                this.$http.post(this.$store.state.apiRoute+'/logout', [])
				.then((response) => {
                    response
                    localStorage.removeItem('access_token_eme');
                    this.$store.state.layout = 'login'
                    this.$store.state.userData = null;
                    // next('login')
                    this.$router.push("login");
                    location.reload();

                })
                .catch(function (error) {
                    error
                })
            },
        },
    }
</script>