<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Nueva requisición
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="8">
                    <v-form ref="form" @submit.prevent="save">
                        <requisicion-form :values="form" :rules="rules" :edition="false"></requisicion-form>
                    </v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="8" class="text-right">
					<v-btn tile text color="primary" :to="{ name: 'Requisiciones' }">Cancelar</v-btn>
					<v-btn tile elevation="0" class="ml-2 btn-yellow" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import RequisicionForm from "./Form.vue";

export default {
    components: {
        'requisicion-form': RequisicionForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                seller_id: '',
                building_id: '',
                sale_order_id: '',
                supplier_id: '',
                date: '',
                // status: 'A',
                products: []
            },
            rules: {
                seller_id: [
                    v => !!v || 'Solicitante es requerido'
                ],
                building_id: [
                    v => !!v || 'Obra es requerido'
                ],
                sale_order_id: [
                    v => !!v || 'Orden de venta es requerido'
                ],
                supplier_id: [
                    v => !!v || 'Proveedor es requerido'
                ],
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        if(typeof this.id !== "undefined")
            this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
            this.$http.get(this.$store.state.apiRoute+'/requisitions/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    seller_id: response.data.data.seller,
                    building_id: response.data.data.building_id,
                    supplier_id: response.data.data.supplier_id,
                    sale_order_id: response.data.data.sale_order_id,
                    date: response.data.data.date.split('/').reverse().join('-'),
                    products: response.data.variants.map((x) => {
                        return {
                            product_variant_id: x.product_variant_id,
                            product_variant: x.product+' | '+x.size+' | '+x.color,
                            quantity: x.quantity,
                            unit: x.unit,
                            actions: ''
                        }
                    })
                }
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){      
                var form = this.form    
                this.form.sale_order_id = this.form.sale_order_id.replace(/\s\s+/g, ' ');
                form.building_id = this.form.sale_order_id.split('-')[1].toLowerCase().trimStart().split(' ').join('-');
                form.sale_order_id = this.form.sale_order_id.split(' ')[0];
                form.products = this.form.products.map((x) => {
                    return {
                        product_variant_id: x.product_variant_id,
                        quantity: x.quantity
                    }
                })
                this.$http.post(this.$store.state.apiRoute+'/requisitions/store', form)
                .then((response) => {
                    response.data
                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente la requisición <b></b>'
                    this.$router.push({ name: 'Requisiciones' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.form.sale_order_id = this.form.sale_order_id
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>