<template>
    <div>
        <v-stepper v-model="stepper" alt-labels>
            <v-stepper-header>
                <v-stepper-step step="1" editable>Datos Generales</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" editable>Variantes</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="mb-12" flat>
                        <v-card-text>
                            <v-row class="py-0" align="center">
                                <v-col>
                                    Tipo de materia: 
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-row no-gutters>
                                        <v-col><v-checkbox class="mt-0" v-model="values.type" label="Materia prima" value="Materia prima" :error="values.type.length <= 0" :error-messages="values.type.length <= 0 ? 'Selecciona un tipo' : []"></v-checkbox></v-col>
                                        <v-col><v-checkbox class="mt-0" v-model="values.type" label="Consumibles" value="Consumibles" :error="values.type.length <= 0"></v-checkbox></v-col>
                                        <v-col><v-checkbox class="mt-0" v-model="values.type" label="Indirectos" value="Indirectos" :error="values.type.length <= 0"></v-checkbox></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-text-field label="Nombre *" v-model="values.name" :rules="rules.name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-text-field label="Descripción *" v-model="values.description" :rules="rules.description"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-text-field label="Marca " v-model="values.brand " :rules="rules.brand"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-autocomplete :items="items.units" label="Unidad *" v-model="values.unit_id " :rules="rules.unit_id" :loading="loadingUnits"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <!-- <div v-if="values.type.find(x => x === 'Materia prima') && (values.unit_id == 'metros' || values.unit_id == 'kilos')">    
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0">
                                        <v-text-field label="Tramos por unidad" v-model="values.sections_per_unit" :rules="rules.sections_per_unit"></v-text-field>
                                    </v-col>
                                </v-row>
                            </div> -->
                        </v-card-text>

                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <div class="text-uppercase font-weight-bold primary--text text-h5">
                                        Proveedores
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0">
                                    <v-autocomplete :items="items.suppliers" label="Selecciona proveedor *" v-model="supplier_id" :rules="rules.supplier_id" :loading="loadingSuppliers"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="py-0" align="center" justify="center">
                                <v-col class="py-0 text-right">
                                    <v-btn text color="primary" @click="addSupplier">Agregar</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            <v-row no-gutters v-for="(supplier, i) in values.suppliers" :key="i" align="center">
                                <v-col>
                                    {{ supplier.name }}
                                </v-col>
                                <v-col cols="1">
                                    <v-btn color="red" icon @click="removeSupplier(i)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn text :to="{ name: 'MateriasPrimas' }">Cancelar</v-btn>
                        <v-btn class="ml-2" tile color="secondary" @click="stepper = 2">Siguiente</v-btn>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-form ref="variantForm" @submit.prevent="addVariant">
                        <v-card class="mb-12" flat>
                            <v-card-text>
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0">
                                        <v-text-field label="Tamaño o medidas *" v-model="variant.size" :rules="variantRules.size"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0">
                                        <v-autocomplete label="Color *" :items="items.colors" v-model="variant.color" :rules="variantRules.color"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0">
                                        <v-text-field label="Precio *" v-model="variant.price" :rules="variantRules.price"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0 text-right">
                                        <v-btn text color="primary" @click="addVariant">{{ editingVariant ? 'Guardar cambios' : 'Agregar' }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text>
                                <div v-if="values.variants.length > 0">
                                    <v-row align="center" no-gutters class="font-weight-bold">
                                        <v-col>Tamaño</v-col><v-col>Color</v-col><v-col>Precio</v-col><v-col></v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                                <div no-gutters v-for="(variant, i) in values.variants" :key="i">
                                    <v-row align="center" no-gutters v-if="variant.edited !== null">
                                        <v-col>
                                            {{ variant.size }}
                                        </v-col>
                                        <v-col>
                                            {{ variant.color}}
                                        </v-col>
                                        <v-col>
                                            {{ variant.price }}
                                        </v-col>
                                        <v-col class="text-right">
                                            <v-btn icon color="primary" @click="editVariant(i)" :disabled="editingVariant || variant.edit"><v-icon>mdi-pencil</v-icon></v-btn>
                                            <v-btn icon color="red" @click="removeVariant(i)" :disabled="editingVariant || variant.edit"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-form>

                    <div class="d-flex">
                        <v-btn text @click="stepper--"><v-icon left>mdi-arrow-left</v-icon> Regresar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text :to="{ name: 'MateriasPrimas' }">Cancelar</v-btn>
                        <v-btn class="ml-2" tile color="secondary" @click="save">Guardar</v-btn>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
export default {
    name: 'materia-prima-form',
    props: ['rules', 'values'],
    computed: {
        editingVariant(){
            return this.variantEdit !== null
        }
    },
    data(){
        return {
            stepper: 1,
            supplier_id: '',
            loadingUnits: false,
            loadingSuppliers: false,
            variantEdit: null,
            variant: {
                size: '', 
                color: '', 
                price: '',
                edit: false,
                edited: false,
                isNew: true
            },
            supplier: {},
            suppliers: [],
            variants: [],
            variantRules: {
                size: [
                    v => !!v || 'Tammaño es requerido'
                ],
                color: [
                    v => !!v || 'Color es requerido'
                ],
                price: [
                    v => !!v || 'Precio es requerido',
                    v => /^(\d*\.)?\d+$/.test(v) || 'Precio debe ser númerico'
                ],
            },
            items: {
                units: [],
                suppliers: [],
                colors: [
                    { value: 'Acero al carbón', text: 'Acero al carbón' },
                    { value: 'Galvanizado', text: 'Galvanizado' },
                    { value: 'Acero inoxidable', text: 'Acero inoxidable' },
                    { value: 'Pintado', text: 'Pintado' },
                    { value: 'No aplica', text: 'No aplica' },
                ]
            }
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loadingUnits = true
            this.$http.get(this.$store.state.apiRoute+'/catalogs/getUnits')
            .then((response) => { this.items.units = response.data })
            .catch((error) => { })
            .finally(() => { this.loadingUnits = false })

            this.loadingSuppliers = true
            this.$http.get(this.$store.state.apiRoute+'/getSuppliers')
            .then((response) => { this.items.suppliers = response.data })
            .catch((error) => { })
            .finally(() => { this.loadingSuppliers = false })
        },
        moveStep(n){
            this.stepper = n
        },
        addSupplier(){
            if(this.supplier_id != ''){
                var validation = this.values.suppliers.findIndex(x => x.slug === this.supplier_id)
                if(validation < 0){
                    var name = this.items.suppliers.find(x => x.value === this.supplier_id)
                    this.supplier = {
                        slug: this.supplier_id,
                        name: name.text
                    }
                    this.values.suppliers.push(this.supplier)
                }
            }
        },
        addVariant(){
            if(this.$refs.variantForm.validate()){
                if(this.variantEdit != null){
                    this.removeVariant(this.variantEdit)
                    this.variant.edit = false
                    this.variant.edited = true
                    this.values.variants.splice(this.variantEdit, 0, JSON.parse(JSON.stringify(this.variant)));
                    this.variantEdit = null
                }else{
                    this.variant.isNew = true
                    this.values.variants.push(JSON.parse(JSON.stringify(this.variant)))
                    
                }
                this.$refs.variantForm.reset()
            }
        },
        editVariant(index){
            this.variantEdit = index
            this.variant = this.values.variants[this.variantEdit]
            this.variant.edit = true
            // this.removeVariant(index)
        },
        removeSupplier(index){
            this.values.suppliers.splice(index, 1)
        },
        removeVariant(index){
            if(this.variantEdit != null){
                this.values.variants.splice(index, 1)
            }else{
                if(this.variant.isNew)
                    this.values.variants.splice(index, 1)
                else
                this.values.variants[index].edited = null
            }
        },
        save(){
            this.$emit('save')
        }
    }
}
</script>

<style>

</style>