<template>
	<div>
		<div class="bg-cover-login login-container d-flex align-center" :style="bgPortrait(`${this.bg.folder}/${this.bg.name}`,`${this.bg.extension}`)">
			<v-container class='flexbox' fluid>
				<div>
					<v-card class='card-logo pl-10 pr-10 pt-16 pb-16' tile>
						<v-row>
							<v-img :alt="this.logos[0].name" :src="image(`${this.logos[0].folder}/${this.logos[0].name}`,`${this.logos[0].extension}`)" height="64" contain/>
						</v-row>
						<v-row>
							<v-form ref="form" v-model="valid" lazy-validation v-if="login">
								<v-col cols xl='12' lg='12' md='12' sm='12'>
									<v-text-field
										class="login-input"
										color="primary"
										hide-details
										v-model="user"
										label="Correo Electronico"
										:rules="[rules.required, rules.email]"
									></v-text-field>
								</v-col>	

								<v-col cols xl='12' lg='12' md='12' sm='12'>
									<v-text-field
										class="login-input"
										color="primary"
										hide-details
										v-model="password"
										:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
										:type="show ? 'text' : 'password'"
										label="Contraseña"
										:rules="rules.password"
										@click:append="show = !show"
									></v-text-field>
								</v-col>
							</v-form>	
							<v-form ref="restore_form" v-model="valid" lazy-validation v-else>
								<v-row>
									<v-col>
										<v-text-field
											v-model="user"
											append-icon="alternate_email"
											label="Email"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row justify="end" align="center">
									<v-col>
										<v-btn text small color="primary" @click="login = !login"><v-icon left small>keyboard_backspace</v-icon>   Regresar</v-btn>
									</v-col>
									<v-col>
										<v-btn color="primary" dark>Restablecer</v-btn>
									</v-col>
								</v-row>
							</v-form>

						</v-row>

						<v-row>
							<v-col cols xl='12' lg='12' md='12' sm='12'>
								<v-btn class='btn-yellow' elevation="0" block @click="loginApi">Iniciar sesión</v-btn>
							</v-col>						
						</v-row>
						<v-row>
							<v-col class='pa-0 ma-0 flexbox' cols xl='12' lg='12' md='12' sm='12' v-if="errorText">
								<span style='font-size: 0.7rem;' class="red--text">{{ errorText }}</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col class='flexbox' cols xl='12' lg='12' md='12' sm='12'>
								<span class="min-text-blue">¿Olvidaste tu contraseña?</span>
							</v-col>
						</v-row>
					</v-card>
					<v-row class='mt-4'>
						<v-col class='flexbox' cols xl='12' lg='12' md='12' sm='12'>
							<span class='mr-1 white--text' style='font-style: italic;'>powered by</span><img :alt="this.logos[1].name" :src="image(`${this.logos[1].folder}/${this.logos[1].name}`,`${this.logos[1].extension}`)" contain height="20"/>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
import {bgImage, image} from "@/mixins/images.js"

export default {
	data: () => ({
		login: true,
		loginIn: false,
		valid: false,
		show: false,
		rememberMe: false,
		user: '',
		password: '',
		loginError: false,
		errorText: '',
		rules: {
			required: value => !!value || 'Correo requerido',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo no válido'
			},
			password: [
				v => !!v || 'Contraseña requerida'
			],
		},
		bg:{
			folder:"login",
			name:"newbg",
			extension:"png"
		},
		logos:[
			{
				folder:"login",
				name:"logo_grupo_merca",
				extension:"png"
			},
			{
				folder:"login",
				name:"logo-yntech-white",
				extension:"png"
			}
		]
	}),
	mixins:[bgImage,image],
	methods: {
		loginApi(){
			if(this.$refs.form.validate()){
				this.loginIn = true
				this.loginError = false
				this.$http.post(this.$store.state.apiRoute+'/login', {
					username: this.user,
					password: this.password
				})
				.then((response) => {                    
					if(typeof response.data.error === 'undefined'){
						const type = response.data.token_type
						const token = response.data.access_token
                        this.$store.state.userData = response.data;
						localStorage.setItem('access_token_eme', type+' '+token)
						this.$router.push('/obras')
					}else{
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'Error en servidor. Intente nuevamente.'
								break;
						}
					}
				})
				.catch((error) => {
					error
				})
				.finally(() => {
					this.loginIn = false
				})
			}
		}
	}
}
</script>

<style>

</style>