<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" lg="4">
						<v-text-field v-model="tableProps.search" label="Buscar un producto" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col class='flexbox-right' cols="12" lg="2" v-permission="'bigProducts.create'">
						<v-btn class='btn-yellow' elevation="0" block :to="{ name: 'ProductosRegistro' }" tile>Nuevo producto</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>
	</div>
</template>

<script>
export default {
	data(){
		return {
			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'bigProducts.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'removeRecord',
                    permissions: 'bigProducts.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Clave',
						align: 'left',
						value: 'sku',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Descripción',
						align: 'center',
						value: 'description',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Precio',
						align: 'center',
						value: 'price',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),
		}
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index(){
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/bigProducts')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['price'] = '$ '+this.numberFormat.format(x.price)
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'ProductosEdicion', params: { id: x.slug } }
					
					x['parameters'] = { id: x.slug }

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
            this.modalProps = {
                visible: true,
                width: '500',
                title: 'Eliminar producto',
                text: '¿Desea continuar?',
                buttons: [
                    {
                        text: 'Cancelar',
                        color: 'primary',
                        textColor: 'white',
                        btnTypeText: true,
                        method: 'cancel',
                        parameters: {}
                    },
                    {
                        text: 'Aceptar',
                        color: 'secondary',
                        textColor: 'white--text mx-1',
                        btnTypeText: false,
                        method: 'confirm',
                        parameters: {
                            id: parameters.id
                        }
                    }
                ]
            }
            this.modalProps.visible = true
        },
		confirm(parameters){
            console.log(parameters);
			this.snackbar = {
				visible: true,
				color: 'orange',
				text: 'Pendiente',
				timeout: 3000
			}
			// this.$http.delete(this.$store.state.apiRoute+'/bigProducts/'+parameters.id+'/delete')
			// .then((response) => {
			// 	this.snackbar = {
			// 		visible: true,
			// 		color: 'green',
			// 		text: 'Se eliminó el producto',
			// 		timeout: 3000
			// 	}
			// 	this.index()
			// }).catch(error => {
			// 	this.snackbar = {
			// 		visible: true,
			// 		color: 'red',
			// 		text: 'Error al eliminar. Intenta de nuevo',
			// 		timeout: 3000
			// 	}
			// })
			// .finally(() => {
			// 	this.modalProps.visible = false
			// })
		},
		cancel(){
			this.modalProps.visible = false
		}
	},
	mounted(){
		this.index()
	}
}
</script>

<style>

</style>