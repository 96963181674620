<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="8" lg="8">
                <v-card flat>
                    <v-card-title>
                        <v-row justify="center">
                            <v-col class="text-center text-uppercase">
                                <div class="d-flex justify-space-between px-1">
                                    <span class="font-weight-bold primary--text">{{ id }}</span>
                                    <v-btn color="secondary" tile :to="{ name: 'OrdenesDeCompra' }">Regresar</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="center">
                            <v-col>
                                <v-row class="px-1">
                                    <v-col cols="6">
                                        <span class="font-weight-bold primary--text">Proveedor: </span> {{ detailsItems.supplier }}
                                    </v-col>
                                    <v-col cols="6" class="text-right">
                                        <span class="font-weight-bold primary--text">Fecha: </span> {{ detailsItems.date }}
                                    </v-col>
                                    <v-col>
                                        <span class="font-weight-bold primary--text">Obra: </span> {{ detailsItems.building }}
                                    </v-col>
                                    <v-col class="text-center">
                                        <span class="font-weight-bold primary--text">Vendedor: </span> {{ detailsItems.seller }}
                                    </v-col>
                                    <v-col class="text-right">
                                        <span class="font-weight-bold primary--text">Tipo de materia a solicitar: </span> {{ detailsItems.supplies }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col>
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col>
                                <span class="font-weight-bold primary--text pl-1">MATERIAS PRIMAS</span>
                                <v-simple-table fixed-header min-height="300px">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left primary--text pl-1">MATERIA PRIMA</th>
                                                <th class="text-center primary--text">CANTIDAD</th>
                                                <th class="text-center primary--text">UNIDAD</th>
                                                <th class="text-right primary--text">COSTO</th>
                                                <th class="text-right primary--text pr-1">SUBTOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product, i) in products" :key="i" class="text-center">
                                                <td class="text-left pl-1">
                                                    {{ product.product }} ({{ product.size }} {{ product.color }})
                                                </td>                                        
                                                <td>{{ product.quantity }}</td>
                                                <td>{{ product.unit }}</td>
                                                <td class="text-right">${{ numberFormat.format(product.amount) }}</td>
                                                <td class="text-right pr-1">${{ numberFormat.format(product.quantity * product.amount) }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th class="text-right primary--text" colspan="4">TOTAL</th>
                                                <th class="text-right primary--text subtitle-2 pr-1">${{ total }}</th>
                                            </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <div v-if="seguimientos.length > 0">
                            <v-row justify="center">
                                <v-col>
                                    <div class="divider-secondary"></div>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col>
                                    <span class="font-weight-bold primary--text pl-1">SEGUIMIENTOS</span>
                                </v-col>
                            </v-row>
                            <v-row class="pl-1" justify="center" v-for="(seguimiento, i) in seguimientos" :key="i">
                                <v-col>
                                    <v-row>
                                        <v-col><span class="font-weight-bold">Fecha: </span> {{ seguimiento.date }}</v-col>
                                        <v-col><span class="font-weight-bold">Próximo contacto: </span>{{ seguimiento.next_date }}</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="text-right"><v-btn color="secondary" icon target="_blank" :href="$store.state.s3Route+seguimiento.file_path"><v-icon>mdi-attachment</v-icon></v-btn></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="2"><div class="font-weight-bold">Comentario: </div></v-col>
                                        <v-col>{{ seguimiento.comments }}</v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="pagos.length > 0">
                            <v-row justify="center">
                                <v-col>
                                    <div class="divider-secondary"></div>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col>
                                    <span class="font-weight-bold primary--text pl-1">PAGOS</span>
                                </v-col>
                            </v-row>
                        </div>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    computed: {
        total(){
            return parseFloat(this.products.reduce(function(a, b){
                return a + (b.quantity * b.amount);
            }, 0)).toFixed(2);
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            fecha: '',
            detailsItems: {
                building: '',
                supplier: '',
                supplies: '',
                seller: '',
                date: '',
            },
            products: [],
            seguimientos: [],
            pagos: [],
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),

        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.$http.get(this.$store.state.apiRoute+'/purchaseOrders/'+this.id+'/show')
            .then((response) => {
                Object.keys(this.detailsItems).forEach((x) => {
                    this.detailsItems[x] = response.data.data[x]
                })

                // this.fecha = response.data.data.date
                this.products = response.data.products
                // this.seguimientos = response.data.tracing
                // this.pagos = response.data.payments
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
.divider-secondary{
    background-color: #cb6120;
    height: 3px;
}
</style>