<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Registrar pago
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4">
                    <v-form ref="form" @submit.prevent="save">
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <datepicker-dialog :picker="dateProps" :dateVal="form.date" @setDate="v => form.date = v" :rules="rules.date"></datepicker-dialog>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <v-text-field label="Monto *" v-model="form.amount" :rules="rules.amount"></v-text-field>
                            </v-col>
                        </v-row>
                        <!--
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <v-autocomplete :items="items.paymentTypes" label="Tipo de pago *" v-model="form.type" :rules="rules.type" :loading="loadingPaymentTypes" append-icon="mdi-chevron-down"></v-autocomplete>
                            </v-col>
                        </v-row>
                        -->
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                 <v-autocomplete :items="items.banks" label="Banco de origen" v-model="form.bank_id" :rules="rules.bank_id" :loading="loadingBanks" append-icon="mdi-chevron-down"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                 <v-autocomplete :items="items.accounts" label="Cuenta receptora *" v-model="form.bank_account_id" :rules="rules.bank_account_id" :loading="loadingAccounts" append-icon="mdi-chevron-down"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <v-file-input label="Factura" v-model="form.invoice_path" append-icon="mdi-cloud-upload" prepend-icon suffix="Seleccionar archivo"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4" class="text-right">
					<v-btn tile text color="primary" :to="{ name: 'OrdenesDeVenta' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
        </v-card-text>
    </v-card>
    <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
export default {
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            loadingPaymentTypes: false,
            loadingBanks: false,
            loadingAccounts: false,
            form: {
                date: '',
                amount: '',
                //type: '',
                bank_id: '',
                bank_account_id: '',
                invoice_path: null,
            },
            rules: {
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
                amount: [
                    v => !!v || 'Monto es requerido',
                    v => /^(\d*\.)?\d+$/.test(v) || 'Monto debe ser númerico'
                ],
                // type: [
                //     v => !!v || 'Tipo de pago es requerido'
                // ],
                bank_id: [
                    
                ],
                bank_account_id: [
                    v => !!v || 'Cuenta receptora es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha de pago *',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            items: {
                paymentTypes: ['Contado','Abono'],
                banks: [],
                accounts: [],
            }
        }
    },
    mounted(){
        this.form.sale_order_id = this.id
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
            this.loadingBanks = true
            this.$http.get(this.$store.state.apiRoute+'/catalogs/getBanks')
            .then((response) => { this.items.banks = response.data })
            .catch(() => { })
            .finally(() => { this.loadingBanks = false })
            
            this.loadingAccounts = true
            this.$http.get(this.$store.state.apiRoute+'/banks')
            .then((response) => { 
                this.items.accounts = response.data.map(x => {
                    return {
                        text: x.alias,
                        value: x.sku
                    }
                })
            })
            .catch(() => { })
            .finally(() => { this.loadingAccounts = false })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                this.$http.post(this.$store.state.apiRoute+'/saleOrders/payment/'+this.id+'/store', this.form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente el pago de <b>'+this.id+'</b>'
                    this.$router.push({ name: 'OrdenesDeVenta' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>