<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Registrar seguimiento
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4">
                    <v-form ref="form" @submit.prevent="save">
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <datepicker-dialog :picker="dateProps" :dateVal="form.date" @setDate="v => form.date = v" :rules="rules.date"></datepicker-dialog>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <datepicker-dialog :picker="nextDateProps" :dateVal="form.next_date" @setDate="v => form.next_date = v"></datepicker-dialog>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <v-textarea label="Comentarios/Observaciones *" v-model="form.comments" no-resize auto-grow :rules="rules.comments"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="py-0" align="center" justify="center">
                            <v-col class="py-0">
                                <v-file-input label="Adjunto" v-model="form.file_path" append-icon="mdi-cloud-upload" prepend-icon suffix="Seleccionar archivo"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4" class="text-right">
					<v-btn tile text color="primary" :to="{ name: 'OrdenesDeVenta' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
        </v-card-text>
    </v-card>
    <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
export default {
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                date: '',
                next_date: '',
                comments: '',
                file_path: [],
            },
            rules: {
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
                comments: [
                    v => !!v || 'Comentarios/Obervaciones es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha *',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            nextDateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Próximo contacto',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
        }
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                var form = new FormData();
                form.append('date', this.form.date)
                form.append('next_date', this.form.next_date)
                form.append('comments', this.form.comments)
                form.append('file_path', this.form.file_path)
                
                this.$http.post(this.$store.state.apiRoute+'/saleOrders/tracing/'+this.id+'/store', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente la orden de venta <b></b>'
                    this.$router.push({ name: 'OrdenesDeVenta' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>