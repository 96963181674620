<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" lg="4">
						<v-text-field v-model="tableProps.search" label="Buscar un proveedor" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col class='flexbox-right' cols="12" lg="2" v-permission="'suppliers.create'">
						<v-btn class='btn-yellow' elevation="0" :to="{ name: 'ProveedoresRegistro' }" tile>Nuevo proveedor</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

		<v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn icon color="white" text v-bind="attrs" @click="snackbar.visible = false">
        <v-icon>mdi-close</v-icon>
        </v-btn>
        </template>
        </v-snackbar>
	</div>
</template>

<script>
export default {
	data(){
		return {
			actions: [
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/proveedores/1/detalles',
                    permissions: 'suppliers.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/proveedores/1/edicion',
                    permissions: 'suppliers.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'removeRecord',
                    permissions: 'suppliers.delete'
				},
			],
			tableProps: {
				headers: [
                        {
							text: 'ID',
							align: 'left',
							value: 'sku',
							class: 'primary--text text-uppercase'
						},
						{
							text: 'Nombre',
							align: 'left',
							value: 'name',
							class: 'primary--text text-uppercase'
						},
						{
							text: 'Estado',
							align: 'center',
							value: 'state',
							class: 'primary--text text-uppercase'
						},
						{
							text: 'Contacto',
							align: 'center',
							value: 'contact',
							class: 'primary--text text-uppercase'
						},
						{
							text: 'Teléfono',
							align: 'center',
							value: 'phone',
							class: 'primary--text text-uppercase'
						},
						{
							text: 'Acciones',
							align: 'right',
							sortable: false,
							value: 'actions',
							class: 'primary--text text-uppercase'
						}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			}
		}
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/suppliers')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['country'] = '-'
					x['state'] = '-'
					// x.address_code = 'ST-SLP'
					if(x.address_code != null){
						const type = x.address_code.substring(0, 2)
						const code = x.address_code.substring(3)
						
						switch(type){
							case "CT":
								this.$http.get(this.$store.state.countryApiRoute+'/api/getCityDetails/'+code)
								.then((response) => { 
									x['country'] = response.data.country.name.common; 
									x['state'] = response.data.state.name
								})
							break;
							case "ST":
								this.$http.get(this.$store.state.countryApiRoute+'/api/getStateDetails/'+code)
								.then((response) => { 
									x['country'] = response.data.country.name.common; 
									x['state'] = response.data.state.name
								})
							break;
							case "CY":
							break;
						}
					}
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'ProveedoresDetalles', params: { id: x.slug } }
					x.actions[1].action = { name: 'ProveedoresEdicion', params: { id: x.slug } }

					x['parameters'] = { id: x.slug }

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
			// eslint-disable-next-line no-console
			console.log(parameters)
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Eliminar proveedor',
				text: '¿Desea continuar?',
				buttons: [
					{
						text: 'Cancelar',
						color: 'primary',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: {}
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'white--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: {
							id: parameters.id
						}
					}
				]
			}
			this.modalProps.visible = true
		},
		confirm(parameters){
			this.$http.delete(this.$store.state.apiRoute+'/suppliers/'+parameters.id+'/delete')
			.then(() => {
				this.snackbar = {
					visible: true,
					color: 'green',
					text: 'Se eliminó el proveedor',
					timeout: 3000
				}
				this.index()
			}).catch(error => {
                console.error(error);
				this.snackbar = {
					visible: true,
					color: 'red',
					text: 'Error al eliminar. Intenta de nuevo',
					timeout: 3000
				}
			})
			.finally(() => {
				this.modalProps.visible = false
			})
		},
		cancel(){
			this.modalProps.visible = false
		}
	},
	mounted(){
		this.index()
	}
}
</script>

<style>

</style>