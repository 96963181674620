<template>
    <div>
	    <v-card flat>
            <v-card-title>
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" class="text-center text-uppercase">
                        <h3>Detalles de la requisición</h3>
                    </v-col>
                </v-row>
            </v-card-title>
		    <v-card-text>
                <v-row justify="center" class="px-4">
                    <v-col cols="12" md="8" lg="8">
                        <v-row>
                            <v-col>
                                <span class="font-weight-bold primary--text">Folio: </span>{{ id }}
                            </v-col>
                            <v-col class="text-right">
                                <span class="font-weight-bold primary--text">Fecha: </span>{{ fecha }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-for="(item, i) in detailsItems[0].items" :key="i"><span class="font-weight-bold primary--text">{{ item.concepto }}: </span> {{ item.valor }}</v-col>
                        </v-row>
                        <!-- <details-card :detailsItems="detailsItems"></details-card> -->
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="8" lg="8">
                        <v-simple-table fixed-header min-height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left primary--text">MATERIA PRIMA</th>
                                        <th class="text-center primary--text">MARCA</th>
                                        <th class="text-center primary--text">UNIDAD</th>
                                        <th class="text-center primary--text">COLOR</th>
                                        <th class="text-center primary--text">TAMAÑO</th>
                                        <th class="text-center primary--text">CANTIDAD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, i) in products" :key="i" class="text-center">
                                        <td class="text-left">{{ product.product }}</td>
                                        <td>{{ product.brand }}</td>
                                        <td>{{ product.unit }}</td>
                                        <td>{{ product.color }}</td>
                                        <td>{{ product.size }}</td>
                                        <td>{{ product.quantity }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
		    </v-card-text>
            <v-card-actions>
                 <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" class="text-right">
                        <v-btn color="secondary" tile :to="{ name: 'Requisiciones' }">Regresar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
	    </v-card>
    </div>
</template>

<script>
export default {
    computed: {
        total(){
            return parseFloat(this.products.reduce(function(a, b){
                return a + b.total;
            }, 0)).toFixed(2);
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Solcitante', index: 'seller', valor: null },
                        { concepto: 'Obra', index: 'building', valor: null },
                        { concepto: 'Orden de venta', index: 'sale_order_id', valor: null },
                        { concepto: 'Proveedor', index: 'supplier', valor: null },
                        { concepto: 'Estatus', index: 'status', valor: null },
                    ],
                }
            ],
            products: [],
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.$http.get(this.$store.state.apiRoute+'/requisitions/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        // if(y.index === 'sale_order_id')
                        //     y.valor = response.data.data[y.index] === null ? '' : response.data.data[y.index].join(', ')
                        // else
                            y.valor = response.data.data[y.index] === null ? '' : response.data.data[y.index]
                    })
                })

                this.fecha = response.data.data.date
                this.products = response.data.variants
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
</style>