<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Registrar nueva materia prima
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="5">
                    <v-form ref="form" @submit.prevent="save">
                        <materia-prima-form ref="materiaPrimaForm" :values="form" :rules="rules" @save="save"></materia-prima-form>
                    </v-form>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import MateriaPrimaForm from "./Form.vue";
export default {
    components: {
        'materia-prima-form': MateriaPrimaForm,
    },
	data(){
		return {
            loading: false,
            form: {
                type: [],
                name: '',
                description: '',
                brand: '',
                unit_id: '',
                suppliers: [],
                variants: [],
                // sections_per_unit: ''
            },
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido'
                ],
                type: [
                    v => v.length > 0 || 'Tipo es requerido'
                ],
                description: [
                    v => !!v || 'Descripción es requerido'
                ],
                brand: [
                    // v => !!v || 'Marca es requerido'
                ],
                unit_id: [
                    v => !!v || 'Unidad es requerido'
                ],
                // sections_per_unit: [
                //     v => {
                //         if(v.trim(v) !== '')//si el campo esta vacío no retorna ninguna regla.
                //             if(!/^(\d*\.)?\d+$/.test(v))//si el campo no es numerico retorna la regla.
                //                 return 'Tramos por unidad debe ser númerico';
                //             else return true;
                //         else return true;
                //     }
                // ]
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        
    },
	methods: {
		validate () {
			return this.$refs.form.validate() && this.form.type.length > 0
		},
        save(){
            this.$store.state.overlay = true

            if(this.validate()){               
                let form = JSON.parse(JSON.stringify(this.form))
                form.type = this.form.type.join(',')
                form['supplier_id'] = this.form.suppliers.map(x => {
                    return x.slug
                })
                form.variants = this.form.variants.map(x => {
                    return { size: x.size, color: x.color, price: x.price }
                })
                // form['sections_per_unit'] = this.form.sections_per_unit;

                this.$http.post(this.$store.state.apiRoute+'/products/store', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente la materia prima <b>'+this.form.name+'</b>'
                    this.$router.push({name: 'MateriasPrimas'})
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.$refs.materiaPrimaForm.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>