<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Registrar nuevo proveedor
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="5">
                    <v-form ref="form" @submit.prevent="save">
                        <proveedor-form :values="form" :rules="rules"></proveedor-form>
                    </v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="5" class="text-right">
					<v-btn tile text color="primary" to="/proveedores">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import ProveedorForm from "./Form.vue";
export default {
    components: {
        'proveedor-form': ProveedorForm,
    },
	data(){
		return {
            loading: false,
            form: {
                name: '',
                rfc: '',
                business_name: '',
                address_code: '',
                neighborhood: '',
                street: '',
                ext_number: '',
                int_number: '',
                contact: '',
                phone: '',
                email: '',
                supplies: [],
            },
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido'
                ],
                rfc: [
                    v => (v.length == 0 || (v.length >= 12  && v.length <= 13)) || 'RFC debe contener de 12 a 13 carácteres'
                ],
                business_name: [
                    v => !!v || 'Razón social es requerido'
                ],
                neighborhood: [
                    v => !!v || 'Colonia es requerido'
                ],
                street: [
                    v => !!v || 'Calle es requerido'
                ],
                ext_number: [
                    v => !!v || 'No. Ext. es requerido',
                    v => /^[0-9]*$/.test(v) || 'No. Interior sólo debe contener números',
                ],
                int_number: [],
                contact: [
                    v => !!v || 'Contacto es requerido'
                ],
                phone: [
                    // v => !!v || 'Teléfono es requerido',
                    v => /^[0-9]*$/.test(v) || 'Teléfono sólo debe contener números',
                    v => (v.length == 0 || v.length == 10) || 'Teléfono debe contener 10 digitos'
                ],
                email: [
                    v => (v.length == 0 || /.+@.+\..+/.test(v)) || 'Correo debe ser con formato válido',
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                var form = JSON.parse(JSON.stringify(this.form))
                form.supplies = form.supplies.join(',')
                this.$http.post(this.$store.state.apiRoute+'/suppliers/store', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente el proveedor <b>'+this.form.name+'</b>'
                    this.$router.push('/proveedores')
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>