<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.sellers" label="Solicitante *" v-model="values.seller_id" :rules="rules.seller_id" :loading="loadingSellers" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col>
            <!-- v-col class="py-0">
                <v-autocomplete :items="items.buildings" label="Obra *" v-model="values.building_id" :rules="rules.building_id" :loading="loadingBuildings" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col -->
            <v-col class="py-0">
                <datepicker-dialog :picker="dateProps" :dateVal="values.date" @setDate="v => values.date = v" :rules="rules.date"></datepicker-dialog>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.saleOrders" label="Orden de trabajo *" v-model="values.sale_order_id" :rules="rules.sale_order_id" :loading="loadingSaleOrders" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
            <v-col class="py-0">
                <v-autocomplete :items="items.suppliers" label="Proveedor *" v-model="values.supplier_id" :rules="rules.supplier_id" :loading="loadingSuppliers" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="text-uppercase font-weight-bold primary--text text-h5">
                    Materias primas
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete :items="items.products" label="Materia prima *" v-model="product_id" :loading="loadingProducts" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0 text-right">
                <v-btn class="py-0" color="primary" text tile @click="addProducts">Agregar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table :headers="tableProps.headers" :items="values.products" disable-sort disable-pagination> <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog :return-value.sync="props.item.quantity">
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.quantity }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.quantity" label="Cantidad"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn x-small class="mx-0" icon @click="removeProduct(item.product_variant_id)">
                                            <v-icon color="secondary" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'requisition-form',
    props: ['rules', 'values', 'edition'],
    computed: {
        supplier_id(){
            return this.values.supplier_id
        }
    },
    data(){
        return {
            product_id: '',
            loadingSellers: false,
            loadingBuildings: false,
            loadingSaleOrders: false,
            loadingSuppliers: false,
            loadingProducts: false,
            products: [],
            items: {
                sellers: [
                    { text: 'Roberto Mercado Ugalde', value: 'Roberto Mercado Ugalde' },
                    { text: 'Roberto Guadiana', value: 'Roberto Guadiana' },
                    { text: 'Eduardo López', value: 'Eduardo López' },
                    { text: 'Mario Mercado Ugalde', value: 'Mario Mercado Ugalde' },
                    { text: 'Francisco Aguiñaga', value: 'Francisco Aguiñaga' },
                    { text: 'Fernando Ramos', value: 'Fernando Ramos' },
                    { text: 'Administración', value: 'Administración' },
                    { text: 'Mónica Páez', value: 'Mónica Páez' },
                    { text: 'Salma Vázquez', value: 'Salma Vázquez' },
                    { text: 'Sergio Mercado Ugalde', value: 'Sergio Mercado Ugalde' },
                    { text: 'José Serrano', value: 'José Serrano' },
                    { text: 'Recursos Humanos', value: 'Recursos Humanos' },
                    { text: 'Jorge Mercado Ugalde', value: 'Jorge Mercado Ugalde' },
                    { text: 'Aidee Garay', value: 'Aidee Garay' },
                    { text: 'Gustavo Reinteria', value: 'Gustavo Reinteria' },
                ],
                //buildings: [],
                saleOrders: [],
                suppliers: [],
                products: []
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            tableProps: {
                headers: [
                    {
                        text: 'Materia prima',
                        align: 'left',
                        value: 'product_variant',
                        width: '40%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Unidad',
                        align: 'center',
                        value: 'unit',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: '',
                        align: 'right',
                        value: 'actions',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                ],
            }
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            // this.loadingBuildings = true
            // this.$http.get(this.$store.state.apiRoute+'/getBuildings')
            // .then((response) => { this.items.buildings = response.data })
            // .catch((error) => { })
            // .finally(() => { this.loadingBuildings = false })
            
            this.loadingSaleOrders = true
            this.$http.get(this.$store.state.apiRoute+'/getSaleOrders')
            .then((response) => { this.items.saleOrders = response.data })
            .catch((error) => { })
            .finally(() => { this.loadingSaleOrders = false })
            
            this.loadingSuppliers = true
            this.$http.get(this.$store.state.apiRoute+'/getSuppliers')
            .then((response) => { this.items.suppliers = response.data })
            .catch((error) => { })
            .finally(() => { this.loadingSuppliers = false })
        },
        getProducts(supplier){
            this.loadingProducts = true
            this.$http.get(this.$store.state.apiRoute+'/getProducts/'+supplier)
            .then((response) => { this.items.products = response.data })
            .catch((error) => { })
            .finally(() => { this.loadingProducts = false })
        },
        getProductName(id){
            var product = this.items.products.find(x => x.value === id)
            return product.text.split('|').join(' | ');
        },
        getProductVariant(product_variant){
            let product_array = product_variant.split('|');
            product_array.pop();
            return product_array.join(' | '); 
        },
        getProductUnit(product_variant){
            var unit = product_variant.split('|').pop();
            return unit;
        },
        addProducts(){
            if(this.product_id != ''){
                var validation = this.values.products.findIndex(x => x.product_variant_id === this.product_id)
                if(validation < 0){
                    var name = this.items.products.find(x => x.value === this.product_id)
                    var product_variant = this.getProductName(this.product_id)
                    var product_t = {
                        product_variant_id: this.product_id,
                        product_variant: this.getProductVariant(product_variant),
                        quantity: 1,
                        unit: this.getProductUnit(product_variant),
                        amount: 0,
                        subtotal: '0',
                        actions: ''
                    }
                    this.values.products.push(product_t)
                }
            }
        },
        removeProduct(id){
            console.log(id)
            let found = this.values.products.findIndex(element => element.product_variant_id == id);
            this.values.products.splice(found, 1)
        },
        save(){
            this.$emit('save')
        }
    },
    watch: {
        supplier_id: function(newValue){
            this.getProducts(newValue)
        }
    }
}
</script>

<style>

</style>