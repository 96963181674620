<template>
    <v-dialog v-model="props.visible" persistent :width="props.width">
      <v-card>
            <v-card-title class="headline">{{ props.title }}</v-card-title>
            <v-card-text>{{ props.text }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div v-for="(btn, i) in props.buttons" :key="i">
                    <v-btn tile :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" @click="method_handler(btn.method, btn.parameters)">{{ btn.text }}</v-btn>
                </div>
            </v-card-actions>
      </v-card>
    </v-dialog>    
</template>
<script>
    export default {
        props: ['props'],
        methods: {
            method_handler(method_name, parameters){
                this.$emit('modalResponse', {
                    method_name: method_name, 
                    parameters: parameters
                })
            }
        }
    }
</script>