<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Editar unidad
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4">
					<v-form ref="form" @submit.prevent="save">
						<v-row no-gutters>
							<v-col>
								<v-text-field v-model="form.name" label="Nombre *" :rules="rules.name"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4" class="text-right">
					<v-btn tile text color="primary" :to="{name: 'CatalogosUnidades'}">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
		  </v-card-text>
	  </v-card>
	  <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
export default {
	data(){
		return {
            id: this.$route.params.id,
			form: {
				name: '',
				type: ''
			},
			rules: {
				name: [
					v => !!v || 'Nombre es requerido'
				]
			},
			snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
			},
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
		index(){
			this.$store.state.overlay = true
            this.$http.get(this.$store.state.apiRoute+'/catalogs/unit/'+this.id+'/edit')
            .then((response) => {
                this.form = {
					name: response.data.name,
					type: response.data.type
				}
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        },
		save(){
			if(this.validate()){
				this.$store.state.overlay = true
				this.$http.put(this.$store.state.apiRoute+'/catalogs/unit/'+this.id+'/update', this.form)
				.then((response) => {
					this.$store.state.globalSnackbarProps.visible = true
					this.$store.state.globalSnackbarProps.text = 'Se ha actualizado correctamente la unidad <b>'+this.form.name+'</b>'
					this.$router.push({ name: 'CatalogosUnidades' })
				}).catch((error) => {
					var errors = []
					switch(error.response.status){
						case 422: 
							Object.keys(error.response.data.errors).forEach((x) => {
								error.response.data.errors[x].forEach((y) => {
									errors.push(y)
								})
							})
						break;
						case 500: 
							errors.push('Error en el servidor. Intente de nuevo.')
						break;
						case 404: 
							errors.push('404 Not found.')
						break;
					}

					this.snackbarProps = {
						visible: true,
						color: 'error',
						timeout: 10000,
						text: errors.join('.<br>'),
					}
				})
				.finally(() => {
					this.$store.state.overlay = false
				})
			}else{
				this.snackbarProps = {
					visible: true,
					color: 'error',
					timeout: 2000,
					text: 'Formulario incompleto',
				}

				this.$store.state.overlay = false
			}
		}
	}
}
</script>

<style>

</style>