<template>
    <div>
        <!-- <v-row align-content="center" justify="center">
            <v-col>
                <v-autocomplete label="Empleado *" :items="items.sellers" v-model="values.seller_id" :rules="rules.seller_id" :disabled="edit"></v-autocomplete>
            </v-col>
        </v-row> -->

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Nombre *" v-model="values.name" :rules="rules.name"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Teléfono *" counter="10" v-model="values.phone " :rules="rules.phone"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Correo electrónico " v-model="values.email " :rules="rules.email"></v-text-field>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center">
            <v-col>
                <v-autocomplete label="rol *" :items="items.roles" v-model="values.role_id" :rules="rules.required"></v-autocomplete>
            </v-col>
        </v-row>
        <!-- <v-row align-content="center" justify="center">
            <v-col>
                <v-text-field label="Correo *" v-model="values.email" disabled></v-text-field>
            </v-col>
        </v-row> -->
        <v-row align-content="center" justify="center" v-if="!edit">
            <v-col>
                <v-text-field label="Contraseña *" v-model="values.password" :rules="rules.password" counter :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"></v-text-field>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center" v-if="!edit">
            <v-col>
                <v-text-field label="Confirmar contraseña *" v-model="values.password_confirmation" :rules="rules.password_confirmation" counter :append-icon="showPassCon ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassCon ? 'text' : 'password'" @click:append="showPassCon = !showPassCon"></v-text-field>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center">
            <v-col>
                <v-switch v-model="values.status" inset :label="`Estatus: ${values.status}`" true-value="Activo" false-value="Inactivo"></v-switch>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: ['rules', 'values', 'edit'],
    computed: {
        // seller_id(){
        //     return this.values.seller_id
        // }
    },
    data(){
        return{
            showPass: false,
            showPassCon: false,
            items: {
                // sellers: [],
                roles: []
            },
        }
    },
    mounted(){
        // this.getEmployees();
        this.getRoles();
    },
    methods: {
        // getEmployees(){
        //     this.$http.get(this.$store.state.apiRoute+'/getSellers')
        //     .then((response) => {
        //         this.items.sellers = response.data
        //     })
        // },
        getRoles(){
            this.$http.get(this.$store.state.apiRoute+'/getRoles')
            .then((response) => {
                this.items.roles = response.data;
            })
        }
    },
    watch: {
        // seller_id(newVal){
        //     this.$http.get(this.$store.state.apiRoute+'/getEmail/'+newVal)
        //     .then((response) => {
        //         this.values.email = response.data.email
        //     })
        // }
    }
}
</script>