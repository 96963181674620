<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" lg="4">
						<v-text-field v-model="tableProps.search" label="Buscar un usuario" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="12" lg="2" class='flexbox-right' v-permission="'users.create'">
						<v-btn class='btn-yellow' elevation="0" block :to="{ name: 'UsuariosRegistro' }" tile>Nuevo usuario</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-dialog v-model="updateModal" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Actualizar contraseña</v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="updatePassword">
                        <v-row align-content="center" justify="center" no-gutters>
                            <v-col>
                                <v-text-field label="Contraseña *" v-model="password" :rules="rules.password" counter :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align-content="center" justify="center" no-gutters>
                            <v-col>
                                <v-text-field label="Confirmar contraseña *" v-model="password_confirmation" :rules="rules.password_confirmation" counter :append-icon="showPassCon ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassCon ? 'text' : 'password'" @click:append="showPassCon = !showPassCon"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeModal">Cancelar</v-btn>
                    <v-btn color="secondary" dark @click="updatePassword">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

        <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn icon color="white" text v-bind="attrs" @click="snackbar.visible = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
export default {
	data(){
		return {
			updateModal: false,
			updateId: null,
			password: '',
			password_confirmation: '',
			showPass: false,
            showPassCon: false,
			rules: {
				password: [
					v => !!v || 'Contraseña es requerido'
				],
				password_confirmation: [
					v => !!v || 'Confirmación de contraseña es requerido',
					v => v == this.password || 'Las contraseñas no coinciden'
				]  
			},
			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'users.update'
				},
				{
					name: 'Restablecer contraseña',
					icon: {
						color: 'secondary',
						icon: 'mdi-restore'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'restorePassword',
                    permissions: 'users.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'removeRecord',
                    permissions: 'users.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Usuario',
						align: 'left',
						value: 'name',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Email',
						align: 'left',
						value: 'email',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Estatus',
						align: 'left',
						value: 'status',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			}
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/users')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'UsuariosEdicion', params: { id: x.slug } }

					x['parameters'] = { id: x.slug }

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		updatePassword(){
			if(this.$refs.form.validate()){
				this.$http.put(this.$store.state.apiRoute+'/users/'+this.updateId+'/restore', { password : this.password, password_confirmation: this.password_confirmation })
				.then((response) => {
					response
					this.updateModal = false
					this.$store.state.globalSnackbarProps.visible = true
					this.$store.state.globalSnackbarProps.text = 'Se ha actualizado la contraseña correctamente'
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log(error)
				})
				.finally(() => {
					this.loading = false
				})
			}
		},
		closeModal(){
			this.updateModal = false
			this.updateId = null
			this.password = ''
			this.password_confirmation = ''
		},
		restorePassword(parameters){
			this.updateModal = true
			this.updateId = parameters.id
		},
		removeRecord(parameters){
                // eslint-disable-next-line no-console
                console.log(parameters)
				this.modalProps = {
					visible: true,
					width: '500',
					title: 'Eliminar usuario',
					text: '¿Desea continuar?',
					buttons: [
						{
							text: 'Cancelar',
							color: 'primary',
							textColor: 'white',
							btnTypeText: true,
							method: 'cancel',
							parameters: {}
						},
						{
							text: 'Aceptar',
							color: 'secondary',
							textColor: 'white--text mx-1',
							btnTypeText: false,
							method: 'confirm',
							parameters: {
								id: parameters.id
							}
						}
					]
				}
                this.modalProps.visible = true
            },
            confirm(parameters){
                this.$http.delete(this.$store.state.apiRoute+'/users/'+parameters.id+'/delete')
                .then(() => {
					this.snackbar = {
						visible: true,
						color: 'green',
						text: 'Se eliminó el usuario',
						timeout: 3000
					}
                    this.index()
                }).catch(error => {
                    console.error(error);
					this.snackbar = {
						visible: true,
						color: 'red',
						text: 'Error al eliminar. Intenta de nuevo',
						timeout: 3000
					}
                })
                .finally(() => {
                    this.modalProps.visible = false
                })
            },
            cancel(){
                this.modalProps.visible = false
            }
	},	
}
</script>

<style>

</style>