<template>
	<div>
        <v-container fluid>
			<sidemenu></sidemenu>
			<navbar></navbar>
			<v-main>
				<router-view></router-view>
			</v-main>
        </v-container>
	</div>
</template>
<script lang="ts">
	import Navbar from '@/components/Navbar.vue'
	import Sidemenu from '@/components/Sidemenu.vue'

	export default {
		name: 'Dashboard',
		components: {
			Navbar,
			Sidemenu,
		},
	}
</script>