<template>
    <div>
	    <v-card flat>
            <v-card-title>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="4">
                        <h3>Detalles del banco</h3>
                    </v-col>
                </v-row>
            </v-card-title>
		    <v-card-text>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="6" lg="4">
                        <details-card :detailsItems="detailsItems"></details-card>        
                    </v-col>
                </v-row>
		    </v-card-text>
            <v-card-actions>
                 <v-row justify="center">
                    <v-col cols="12" md="6" lg="4" class="text-right">
                        <v-btn color="secondary" tile :to="{ name: 'Bancos' }">Regresar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
	    </v-card>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Alias', index: 'alias', valor: null },
                        { concepto: 'Tipo', index: 'type', valor: null },
                        { concepto: 'Banco', index: 'bank', valor: null },
                        { concepto: 'Número de cuenta', index: 'account_number', valor: null },
                        { concepto: 'Saldo inicial', index: 'initial_balance', valor: null },
                        { concepto: 'Fecha de saldo inicial', index: 'initial_balance_date', valor: null },                    
                    ],
                }
            ]
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.$http.get(this.$store.state.apiRoute+'/banks/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data[y.index] === null ? '' : response.data[y.index]
                    })
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        }
    }
}
</script>

<style>
</style>