<template>
  <v-app>
    <component :is="this.$store.state.layout"></component>
    
    <global-snackbar :props="$store.state.globalSnackbarProps"></global-snackbar>
    <loading-overlay></loading-overlay>
  </v-app>
</template>

<script>
import Vue from 'vue';
import "@/assets/scss/_stylesheet.scss";


export default Vue.extend({
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
  mounted(){
  }
});
</script>
