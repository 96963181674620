<template>
  <div>
	<v-card flat>
		<v-card-text>
			<v-row justify="center">
				<v-col class="text-center">
					<div class="text-uppercase font-weight-bold primary--text text-h5">
						Registrar nuevo usuario
					</div>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4">
					<v-form ref="form" @submit.prevent="save">
						<users-form :rules="rules" :values="form" :edit="false"></users-form>
					</v-form>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="4" class="text-right">
					<v-btn tile text color="primary" :to="{ name: 'Usuarios' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-2" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
        </v-card-text>
    </v-card>
    <snackbar :props="snackbarProps"></snackbar>
  </div>
</template>

<script>
import UsersForm from './Form.vue'

export default {
	components: {
		'users-form': UsersForm
	},
	data(){
		return {
			form: {
				// seller_id: '',
                name: '',
                phone: '',
				email: '',
				password: '',
				password_confirmation: '',
				status: 'Activo',
                role_id: '',
			},
			rules: {
                required: [
					v => !!v || 'El campo es requerido'
				],
				seller_id: [
					v => !!v || 'Nombre es requerido'
				],
				password: [
					v => !!v || 'Contraseña es requerido'
				],
				password_confirmation: [
					v => !!v || 'Confirmación de contraseña es requerido',
					v => v == this.form.password || 'Las contraseñas no coinciden'
				],
                phone: [
                    v => !!v || 'Teléfono es requerido',
                    v => /^[0-9]*$/.test(v) || 'Teléfono sólo debe contener números',
                    v => v.length === 10 || 'Teléfono debe contener 10 digitos'
                ],
                email: [
                    v => (v.length == 0 || /.+@.+\..+/.test(v)) || 'Correo debe ser con formato válido',
                ],
			},
			snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
			},
		}
	},
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
		save(){
			if(this.validate()){
				this.$store.state.overlay = true
				this.$http.post(this.$store.state.apiRoute+'/users/store', this.form)
				.then(() => {
					this.$store.state.globalSnackbarProps.visible = true
					this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente el usuario'
					this.$router.push({ name: 'Usuarios' })
				}).catch((error) => {
					var errors = []
					switch(error.response.status){
						case 422: 
							Object.keys(error.response.data.errors).forEach((x) => {
								error.response.data.errors[x].forEach((y) => {
									errors.push(y)
								})
							})
						break;
						case 500: 
							errors.push('Error en el servidor. Intente de nuevo.')
						break;
						case 404: 
							errors.push('404 Not found.')
						break;
					}

					this.snackbarProps = {
						visible: true,
						color: 'error',
						timeout: 10000,
						text: errors.join('.<br>'),
					}
				})
				.finally(() => {
					this.$store.state.overlay = false
				})
			}else{
				this.snackbarProps = {
					visible: true,
					color: 'error',
					timeout: 2000,
					text: 'Formulario incompleto',
				}

				this.$store.state.overlay = false
			}
		}
	}
}
</script>

<style>

</style>